import React from 'react';

export const Products: React.FC = () => (
  <div className="container-xxl feature py-5">
    <div className="container py-5">
      <div className="row g-5 align-items-center py-5">
        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
          <p className="d-inline-block border rounded font-primary fw-semi-bold py-1 px-3">Our Products</p>
          <h1 className="display-5 mb-4">Versatile Solutions, Value Delivered Globally.</h1>
          <p className="mb-4">We are addressing user challenges through a diverse range of products, seeking solutions and delivering value across multiple domains.
          </p>
          <a className="btn btn-primary py-3 px-5" href="/products">Explore Our Products</a>
        </div>
        <div className="col-lg-6">
          <div className="row g-4 align-items-center">
            <div className="col-md-6">
              <div className="row g-4">
                <div className="col-12 wow fadeIn" data-wow-delay="0.3s">
                  <div className="feature-box border rounded p-4">
                    <i className="fa fa-check fa-3x text-primary mb-3" />
                    <h4 className="mb-3">Timely Delivery</h4>
                    <p className="mb-3">We prioritize prompt project delivery, ensuring your timelines are met. Our commitment to timely completion reflects our dedication to efficiency and professionalism.
                    </p>
                  </div>
                </div>
                <div className="col-12 wow fadeIn" data-wow-delay="0.5s">
                  <div className="feature-box border rounded p-4">
                    <i className="fa fa-check fa-3x text-primary mb-3" />
                    <h4 className="mb-3">Cost-Effective Solutions</h4>
                    <p className="mb-3">Putting your budget first, we offer pocket-friendly solutions. This approach allows you to channel your resources towards business growth instead of hefty expenses in product development.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 wow fadeIn" data-wow-delay="0.7s">
              <div className="feature-box border rounded p-4">
                <i className="fa fa-check fa-3x text-primary mb-3" />
                <h4 className="mb-3">Trustworthy Partnerships</h4>
                <p className="mb-3">Trust is at the core of our values. We consistently strive to provide solutions aligned with your business model, fostering long-term partnerships built on reliability and mutual success.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Products;
