import './public.scss';
import WDSFooter from '../../components/public/home/footer';
import ProductNavbar from '../../components/public/products/nav-product';
import ProductIntro from '../../components/public/products/intro-product';
import YoCard from '../../components/public/products/yo-card';
import ACPS from '../../components/public/products/acps';
import CampusXcel from '../../components/public/products/campusxcel';
import { CryoCanva } from '../../components/public/products/cryocanva';
import { Powermart } from '../../components/public/products/powermart';
import { MyPrint } from '../../components/public/home/my-print';

export const PublicProductsComponent: React.FC = () => (
  <div>
    <ProductNavbar />
    <ProductIntro />
    <div className="pt-2 my-pring-bg">
      <MyPrint />
    </div>
    <div className="pt-2">
      <Powermart />
    </div>
    <div className="pt-2">
      <YoCard />
    </div>
    <div className="pt-2">
      <ACPS />
    </div>
    <div className="pt-2">
      <CampusXcel />
    </div>
    <div className="pt-2">
      <CryoCanva />
    </div>
    <div>
      <WDSFooter />
    </div>
  </div>
);

export default PublicProductsComponent;
