import React from 'react';

export const CryoCanva: React.FC = () => (
  <div className="container-xxl feature py-5">
    <div className="container py-3">
      <div className="row g-5 align-items-center py-1">
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
          <p className="d-inline-block border bg-black rounded font-primary fw-semi-bold py-1 px-3">
            <img
              alt="..."
              height="45px"
              src="/images/products/cryocanva.png"
            />
          </p>
          <h1 className="display-5 mb-4">Revolutionizing Ownership</h1>
          <h5 className="mb-4 text-common">A Unique NFT Fusion with Physical Copies and an Exclusive E-Commerce Platform</h5>
          <p className="mb-4">In this groundbreaking initiative, we merge NFT technology with physical copies, introducing an unparalleled e-commerce platform. Users can enjoy a 100% guarantee of product authenticity, ensuring a seamless and trustworthy ownership experience like never before.
          </p>
          <p className="mb-2 text-lite-product">Stay tuned for the exciting launch of our forthcoming product! Our dedicated team is working tirelessly to introduce an innovative solution that holds immense potential. The success of this endeavor hinges on expanding our team and securing substantial investments. Join us on this journey of boundless possibilities and anticipation.
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default CryoCanva;
