import { combineEpics, Epic } from 'redux-observable';
import {
  isActionOf, RootAction, RootState, Services,
} from 'typesafe-actions';
import { from } from 'rxjs';
import {
  filter, map, mergeMap, catchError,
} from 'rxjs/operators';
import { ContactUsResults } from '../../services/contact-us/contact-us.service.types';
import {
  apiError, contactUsPostReq, updateContactUsPostReq,
} from './actions';

export const contactUsPOSTEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { wdsInfotechService }) => action$.pipe(
  filter(isActionOf(contactUsPostReq)),
  mergeMap((action) => from(wdsInfotechService.contactUsService(action.payload)).pipe(
    map((response : ContactUsResults) => updateContactUsPostReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'WDSInfotech', errorMessage: error.message })),
  )),
);

export const wdsInfotechEpics = combineEpics(
  contactUsPOSTEpic,
);
