import { ActionType, getType, Reducer } from 'typesafe-actions';
import {
  clearContactUsPostReq,
  updateContactUsPostReq, updateData,
} from './actions';
import * as actions from './actions';
import { WDSInfotechAppState } from './action.types';

export const initialState: WDSInfotechAppState = {
  data: {
    show: '',
  },
  contactUs: {
    message: '',
    errorMessage: '',
    error: false,
  },
};
export type AllAction = ActionType<typeof actions>;
const wdsInfotechReducer: Reducer<WDSInfotechAppState, AllAction> = (state: WDSInfotechAppState = initialState, action: AllAction): WDSInfotechAppState => {
  switch (action.type) {
    case getType(updateData):
      return {
        ...state,
        data: {
          show: action.payload,
        },
      };
    case getType(updateContactUsPostReq):
      return {
        ...state,
        contactUs: {
          message: action.payload.message,
          errorMessage: action.payload.errorMessage,
          error: action.payload.error,
        },
      };
    case getType(clearContactUsPostReq):
      return {
        ...state,
        contactUs: initialState.contactUs,
      };
    default:
      return state;
  }
};
export default wdsInfotechReducer;
