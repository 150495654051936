import React from 'react';
import Navbar from '../../components/public/navbar/wdsInfotech.navbar';
import './public.scss';
import Contactus from '../../components/public/contact-us/contact-us';
import WDSFooter from '../../components/public/home/footer';

export const ContactUsComponent: React.FC = () => (
  <div>
    <Navbar />
    <Contactus />
    <div className="footer">
      <WDSFooter />
    </div>
  </div>
);

export default ContactUsComponent;
