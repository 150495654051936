import React from 'react';

export const CampusXcel: React.FC = () => (
  <div className="container-xxl feature py-5">
    <div className="container py-3">
      <div className="row g-5 align-items-center py-1">
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.1s">
          <p className="d-inline-block border rounded font-primary fw-semi-bold py-1 px-3">
            <img
              alt="..."
              height="45px"
              src="/images/products/campusXcel.png"
            />
          </p>
          <div className="align-start mb-3">
            <a href="https://campusxcel.com/" target="_blank" className="alert-error" rel="noreferrer">www.campusxcel.com</a>
          </div>
          <h1 className="display-5 mb-4">Campus to Corporate</h1>
          <p className="mb-4 text-common">CampusXcel first of its kind, employs Artificial Intelligence to bridge the gap between industry and academia, offering mentoring, coaching, and extensive training to provide a 360-degree career development experience that offers a genuine understanding of how the industry operates.
          </p>
          <p className="mb-2 text-lite-product">Exciting new product on the horizon! Stay tuned for its upcoming release as we gear up to unveil a groundbreaking addition to our lineup. Coming soon to enhance your experience.
          </p>
        </div>
      </div>
    </div>
  </div>
);

export default CampusXcel;
