import React from 'react';

export const Powermart: React.FC = () => (
  <div className="container-xxl feature py-1">
    <div className="container py-1">
      <div className="row g-5 align-items-center py-1">
        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
          <div>
            <p className="d-inline-block border rounded font-primary fw-semi-bold py-1 px-3">
              <img
                alt="..."
                height="45px"
                src="/images/products/powerMart.png"
              />
            </p>
          </div>
          <div className="align-start mb-3">
            <a href="https://powermart.in/" target="_blank" className="alert-error" rel="noreferrer">www.powermart.in</a>
          </div>
          <h1 className="display-5 mb-4">PowerMart - Your One-Stop Energy Solution</h1>
          <h6 className="mb-4 text-common">PowerMart is your ultimate destination for all energy-related products and services. Our platform offers a seamless experience where you can not only track your energy consumption but also discover the best deals and access customized products, all in one place..
          </h6>
        </div>
        <div className="col-lg-6">
          <div className="row g-4 align-items-center">
            <div className="col-md-6">
              <div className="row g-4">
                <div className="col-12 wow fadeIn" data-wow-delay="0.3s">
                  <div className="feature-box border rounded p-4">
                    <i className="fa fa-check fa-3x text-primary mb-3" />
                    <h4 className="mb-3">Comprehensive Energy Solutions</h4>
                    <p className="mb-3">Whether you&apos;re looking for electricity, renewable energy sources, or energy-efficient products, PowerMart has everything you need to meet your energy requirements.
                    </p>
                  </div>
                </div>
                <div className="col-12 wow fadeIn" data-wow-delay="0.5s">
                  <div className="feature-box border rounded p-4">
                    <i className="fa fa-check fa-3x text-primary mb-3" />
                    <h4 className="mb-3">Customized Products</h4>
                    <p className="mb-3">Tailored solutions are our specialty. You can customize your energy plans and products to suit your specific needs and preferences, giving you more control over your energy choices.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 wow fadeIn" data-wow-delay="0.7s">
              <div className="feature-box border rounded p-4">
                <i className="fa fa-check fa-3x text-primary mb-3" />
                <h4 className="mb-3">Diverse Product Range</h4>
                <p className="mb-3">Explore a wide range of energy-related products and services on our platform. From solar panels and smart meters to energy-saving devices and eco-friendly solutions, we have it all.Our user-friendly portal is designed for ease of use, making it simple and convenient for you to navigate, compare products, and make informed decisions without any hassle.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Powermart;
