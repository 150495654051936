import React from 'react';

export const AboutUs: React.FC = () => (
  <div className="container-xxl">
    <div className="container">
      <div className="row g-4 align-items-end mb-4">
        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
          <img alt="" className="img-fluid rounded" src="/images/public/about.jpg" />
        </div>
        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.3s">
          <p className="d-inline-block border rounded font-primary fw-semi-bold py-1 px-3">About Us</p>
          <h1 className="display-5 mb-4">We Help Our Clients To Grow Their Business</h1>
          <p className="mb-4">WDSInfotech is a trailblazer in crafting innovative in-house products, meticulously tailored to address the diverse and evolving needs of our esteemed clients. With a steadfast commitment to excellence, we harness cutting-edge technology to deliver unparalleled solutions that drive business growth and success.
          </p>
          <div className="border rounded p-3">
            <nav>
              <div className="nav nav-tabs mb-3" id="nav-tab" role="tablist">
                <button
                  className="nav-link fw-semi-bold active font-primary"
                  id="nav-story-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-story"
                  type="button"
                  role="tab"
                  aria-controls="nav-story"
                  aria-selected="true"
                >Story
                </button>
                <button
                  className="nav-link fw-semi-bold font-primary"
                  id="nav-mission-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-mission"
                  type="button"
                  role="tab"
                  aria-controls="nav-mission"
                  aria-selected="false"
                >Mission
                </button>
                <button
                  className="nav-link fw-semi-bold font-primary"
                  id="nav-vision-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#nav-vision"
                  type="button"
                  role="tab"
                  aria-controls="nav-vision"
                  aria-selected="false"
                >Vision
                </button>
              </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
              <div
                className="tab-pane fade show active"
                id="nav-story"
                role="tabpanel"
                aria-labelledby="nav-story-tab"
              >
                <p className="mb-0">Born from a collective passion for innovation and driven by a commitment to excellence, WDSInfotech embarked on its journey to revolutionize the digital landscape. From humble beginnings, our dedicated team of visionaries and technologists has consistently pushed boundaries, transforming challenges into opportunities and obstacles into stepping stones towards success.
                </p>
              </div>
              <div
                className="tab-pane fade"
                id="nav-mission"
                role="tabpanel"
                aria-labelledby="nav-mission-tab"
              >
                <p className="mb-0">Our mission at WDSInfotech is clear: to empower businesses of all sizes and industries with transformative digital solutions that unlock their full potential. By leveraging the latest advancements in technology and embracing a customer-centric approach, we strive to deliver innovative products and services that drive growth, efficiency, and long-term success for our clients.
                </p>
              </div>
              <div className="tab-pane fade" id="nav-vision" role="tabpanel" aria-labelledby="nav-vision-tab">
                <p className="mb-0">At WDSInfotech, we envision a future where technology serves as a catalyst for positive change and sustainable growth across all sectors. We are committed to being at the forefront of this digital transformation, empowering businesses to thrive in an ever-evolving landscape through our innovative solutions, unwavering dedication, and unwavering commitment to excellence.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="border rounded p-4 wow fadeInUp" data-wow-delay="0.1s">
        <div className="row g-4">
          <div className="col-lg-4 wow fadeIn" data-wow-delay="0.1s">
            <div className="h-100">
              <div className="d-flex">
                <div className="flex-shrink-0">
                  <img style={{ height: '30px', width: 'auto' }} src="/images/public/projects.png" alt="linkdein" />
                </div>
                <div className="ps-3">
                  <h4>In-house Product Development</h4>
                  <span>Providing expert guidance and strategic insights to navigate the complexities of digital transformation effectively</span>
                </div>
                <div className="border-end d-none d-lg-block" />
              </div>
              <div className="border-bottom mt-4 d-block d-lg-none" />
            </div>
          </div>
          <div className="col-lg-4 wow fadeIn" data-wow-delay="0.1s">
            <div className="h-100">
              <div className="d-flex">
                <div className="flex-shrink-0">
                  <img style={{ height: '30px', width: 'auto' }} src="/images/public/engineering.png" alt="linkdein" />
                </div>
                <div className="ps-3 mr-2">
                  <h4>Digital Transformation</h4>
                  <span>Empowering businesses to thrive in the digital age through strategic modernization and innovation</span>
                </div>
                <div className="border-end d-none d-lg-block" />
              </div>
              <div className="border-bottom mt-4 d-block d-lg-none" />
            </div>
          </div>
          <div className="col-lg-4 wow fadeIn" data-wow-delay="0.5s">
            <div className="h-100">
              <div className="d-flex">
                <div className="flex-shrink-0">
                  <img style={{ height: '30px', width: 'auto' }} src="/images/public/tech-softskill.png" alt="linkdein" />
                </div>
                <div className="ps-3">
                  <h4>Consulting and Advisory Services</h4>
                  <span>Providing expert guidance and strategic insights to navigate the complexities of digital transformation effectively</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default AboutUs;
