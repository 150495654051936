import { ContactUsPostRequest } from './contact-us/contact-us.service';
import {
  ContactUsService, ContactUsOptions, ContactUsResponse, ContactUsResults,
} from './contact-us/contact-us.service.types';
import { ContactUsTransform } from './contact-us/contact-us.transform';
import { createService } from './service';

const response = 'Data has been loaded successfully!';

export function loadData(): Promise<string> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(response);
      if (response === undefined) {
        reject();
      }
    }, 500);
  });
}
export const contactUsService: ContactUsService = createService<ContactUsOptions, ContactUsResponse, ContactUsResults>(ContactUsPostRequest, ContactUsTransform);
