import React from 'react';
import './home.scss';

export const Clients: React.FC = () => (
  <div className="container-xxl py-5">
    <div className="container">
      <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s">
        <p className="d-inline-block border rounded font-primary fw-semi-bold py-1 px-3">Thrilled Client Experiences</p>
        <h1 className="display-5 mb-5">Discover the Joy of Being Our Satisfied Clients</h1>
      </div>
      <div className="row g-4">
        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
          <div className="align-center">
            <img className="ayush-img" src="/images/clients/vmepl.jpg" alt="t3" />
          </div>
        </div>
        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
          <div className="align-center">
            <img className="ayush-img" src="/images/clients/purpule-tulips.jpeg" alt="t3" />
          </div>
        </div>
        <div className="col-lg-3 col-md-6 wow fadeInUp pt-4" data-wow-delay="0.1s">
          <div className="align-center">
            <img className="ayush-img" src="/images/clients/PSA-logo003.svg" alt="t3" />
          </div>
        </div>
        <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
          <div className="align-center">
            <img className="ayush-img" src="/images/clients/info-tech.png" alt="t3" />
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Clients;
