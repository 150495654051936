import React from 'react';
// import {
//   faBars, faBuildingUser, faClose, faLaptopFile,
// } from '@fortawesome/free-solid-svg-icons';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { useNavigate } from 'react-router';
// import { NavLink } from 'react-router-dom';

export const Intro: React.FC = () => (
  <div className="container-fluid p-0 wow fadeIn" data-wow-delay="0.1s">
    <div id="header-carousel" className="carousel slide carousel-fade" data-bs-ride="carousel">
      <div className="carousel-inner">
        <div className="carousel-item active carousel-caption-bottom ">
          <img className="w-100" src="/images/public/intro.jpeg" alt="wds1" />
          <div className="carousel-caption">
            <div className="container">
              <div className="row justify-content-start carousel-caption-top-text">
                <div className="col-lg-6">
                  <p
                    className="font-padding welcome-wds d-inline-block border border-grey rounded mx-2 fw-semi-bold animated slideInDown"
                  >
                    Welcome to WdsInfotech
                  </p>
                  <div className="pt-3 mob-text-center-aligned">
                    <h1 className="display-4 mb-1 animated font-intro">Empowering Growth Through Innovation & Collaboration</h1>
                  </div>
                  <div className="pt-5 mob-text-center-aligned">
                    <a href="/products" className="btn btn-primary py-3 px-5 animated slideInDown">Explore More</a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Intro;
