import React, { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';
import PublicWDSInfotechComponent from '../../scenes/public/wdsInfotech.component';
import ContactUsComponent from '../../scenes/public/contactus.component';
import PublicProductsComponent from '../../scenes/public/products.component';

const AppBase: React.FC = (): ReactElement => (
  <Routes>
    <Route path="/" element={<PublicWDSInfotechComponent />} />
    <Route path="/home" element={<PublicWDSInfotechComponent />} />
    <Route path="/products" element={<PublicProductsComponent />} />
    <Route path="/contact-us" element={<ContactUsComponent />} />
  </Routes>
);

export default AppBase;
