import React from 'react';
import { Button } from 'reactstrap';

export const TechSoftSkill: React.FC = () => (
  <div className="container-xxl pt-5 py-5">
    <div className="container pt-5">
      <div className="row g-4 align-items-end mb-4 pt-5">
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <p className="d-inline-block border rounded font-primary fw-semi-bold py-1 px-3">Consulting and Advisory Services</p>
          <h1 className="display-5 mb-4">Providing expert guidance and strategic insights to navigate the complexities of digital transformation effectively</h1>
          {/* <p className="mb-4">Providing expert guidance and strategic insights to navigate the complexities of digital transformation effectively.
          </p> */}
        </div>
        <div className="row g-4 wow fadeInUp" data-wow-delay="0.3s">
          <div className="col-lg-4">
            <div className="nav nav-pills d-flex justify-content-between w-100 h-100 me-4">
              <Button
                className="nav-link w-100 d-flex align-items-center text-start border p-4 mb-4 active"
                data-bs-toggle="pill"
                data-bs-target="#tab-pane-1T"
              >
                <h5 className="m-0"><i className="fa fa-bars text-primary me-3" />Digital Strategy</h5>
              </Button>
              <Button
                className="nav-link w-100 d-flex align-items-center text-start border p-4 mb-4"
                data-bs-toggle="pill"
                data-bs-target="#tab-pane-2T"
              >
                <h5 className="m-0"><i className="fa fa-bars text-primary me-3" />Technology Adoption</h5>
              </Button>
              <Button
                className="nav-link w-100 d-flex align-items-center text-start border p-4 mb-4"
                data-bs-toggle="pill"
                data-bs-target="#tab-pane-3T"
              >
                <h5 className="m-0"><i className="fa fa-bars text-primary me-3" />Innovation Initiatives</h5>
              </Button>
            </div>
          </div>
          <div className="col-lg-8">
            <div className="tab-content w-100">
              <div className="tab-pane fade show active" id="tab-pane-1T">
                <div className="row g-4">
                  <div className="col-md-7">
                    <h3 className="mb-4">Digital Strategy Formulation</h3>
                    <p className="mb-4">Collaborating closely with clients to develop tailored digital strategies aligned with their business goals, industry trends, and market dynamics, ensuring a clear path towards digital success.</p>
                    {/* <p className="mb-4">Industry-Relevant Language: Acquire the language skills necessary to communicate effectively in a tech-driven environment.
                    </p>
                    <p><i className="fa fa-check text-primary me-3" />Secured Loans</p>
                  <p><i className="fa fa-check text-primary me-3" />Credit Facilities</p>
                  <p><i className="fa fa-check text-primary me-3" />Cash Advanced</p> */}
                    <a href="#/" className="btn btn-primary py-3 px-5 mt-3">Read More</a>
                  </div>
                  <div className="col-md-5">
                    <div className="position-relative h-100">
                      <img
                        className="position-absolute rounded w-100 h-100"
                        src="/images/public/tech-skill.jpg"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="tab-pane-2T">
                <div className="row g-4">
                  <div className="col-md-7">
                    <h3 className="mb-4">Technology Adoption Guidance</h3>
                    <p className="mb-4">Offering in-depth analysis and recommendations on the adoption of emerging technologies, platforms, and solutions, enabling clients to make informed decisions that drive innovation and competitive advantage.</p>
                    {/* <p className="mb-4">Hands-On Learning: Engage in practical sessions to reinforce your understanding of various tech tools and products.
                    </p>
                    <p><i className="fa fa-check text-primary me-3" />Secured Loans</p>
                  <p><i className="fa fa-check text-primary me-3" />Credit Facilities</p>
                  <p><i className="fa fa-check text-primary me-3" />Cash Advanced</p> */}
                    <a href="#/" className="btn btn-primary py-3 px-5 mt-3">Read More</a>
                  </div>
                  <div className="col-md-5">
                    <div className="position-relative h-100">
                      <img
                        className="position-absolute rounded w-100 h-100"
                        src="/images/public/gamified.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-pane fade" id="tab-pane-3T">
                <div className="row g-4">
                  <div className="col-md-7">
                    <h3 className="mb-4">Innovation Initiatives</h3>
                    <p className="mb-4">Facilitating innovation workshops, ideation sessions, and proof-of-concept projects to foster a culture of innovation within organizations, empowering teams to explore new ideas, experiment with emerging technologies, and drive continuous improvement.</p>
                    <a href="#/" className="btn btn-primary py-3 px-5 mt-3">Read More</a>
                  </div>
                  <div className="col-md-5">
                    <div className="position-relative h-100">
                      <img
                        className="position-absolute rounded w-100 h-100"
                        src="/images/public/ideal-for.png"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="col-lg-12 wow fadeInUp pt-5" data-wow-delay="0.3s">
        <h3 className="mb-4">Why Choose us</h3>
        <p className="mb-4">Choose WDSInfotech for unparalleled expertise in digital transformation and innovative solutions. We excel in crafting tailored strategies that propel businesses into the digital future, leveraging cutting-edge technologies to drive efficiency and growth.
        </p>
        <p className="mb-4">Our commitment to continuous innovation extends to new initiative ideation,
          where we work closely with clients to explore new opportunities and streamline processes.
          With our products and services, we make life easier for our clients, providing seamless
          integration, optimized workflows, and strategic guidance to navigate the complexities of the
          digital landscape. Trust WDSInfotech to be your partner in success, driving tangible results
          and sustainable growth.
        </p>
      </div>
    </div>
  </div>
);

export default TechSoftSkill;
