import { createAction } from 'typesafe-actions';
import {
  API_ERROR, CLEAR_CONTACT_US_DATA, CONTACT_US_DATA, SHOW_DATA, UPDATE_CONTACT_US_DATA, UPDATE_DATA,
} from './constants';
import { ContactUsOptions, ContactUsResults } from '../../services/contact-us/contact-us.service.types';
import { ErrorAction } from '../../services/types';

export const apiError = createAction(API_ERROR)<ErrorAction>();
export const showData = createAction(SHOW_DATA)<{}>();
export const updateData = createAction(UPDATE_DATA)<string>();
export const contactUsPostReq = createAction(CONTACT_US_DATA)<ContactUsOptions>();
export const updateContactUsPostReq = createAction(UPDATE_CONTACT_US_DATA)<ContactUsResults>();
export const clearContactUsPostReq = createAction(CLEAR_CONTACT_US_DATA)();
