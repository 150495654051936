import {
  Col, Row,
} from 'reactstrap';
import './contact.scss';

export const Contactus: React.FC = () => (
  <div className="contact-wdsInfotech-bg pt-5">
    <Row className="mx-3">
      <Col lg={{ size: 6, order: 1 }} xs={{ order: 1 }}>
        <div className="ml-2 pt-5">
          <span className="display-6 text-intro-sub-header">
            Connect With Us
          </span>
          <div className="pt-5 align-text-justify">
            <span className="text-why pt-5">
              Let&rsquo;s connect over a cup of coffee to discuss and plan the product in a seamless manner.
              <br />
              <br />
              Email us : <a href="mailto:info@wdsinfotech.com" className="text-email">info@wdsinfotech.com</a>
            </span>
          </div>
        </div>
      </Col>
      <Col lg={{ size: 6, order: 2 }} xs={{ order: 2 }}>
        <div className="align-center pt-3">
          <img
            alt="..."
            height="350px"
            src="/images/common/contact.png"
          />
        </div>
      </Col>
    </Row>
  </div>
);

export default Contactus;
