import React from 'react';
import './home.scss';
import { Button, Nav, NavLink } from 'reactstrap';

export const Navbar: React.FC = () => {
  // const history = useNavigate();
  const [isFixed, setIsFixed] = React.useState(false);
  const [isActive, setIsActive] = React.useState('home');

  const handleClick = (status: string) => {
    setIsActive(status);
  };

  const handleScroll = () => {
    // Change the threshold value based on your design
    const threshold = 100;

    if (window.scrollY > threshold) {
      setIsFixed(true);
    } else {
      setIsFixed(false);
    }
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div>
      <div className="container-fluid px-0 wow fadeIn fixed-top" data-wow-delay="0.1s">
        <div className={`top-bar row gx-0 align-items-center d-lg-flex ${isFixed ? 'fixed-top' : 'd-none'}`}>
          <div className="col-lg-6 px-3 pt-2 text-start">
            <NavLink to="/home" className="nav-logo">
              <img style={{ height: '45px', width: 'auto' }} src="/wdsInfotech.png" alt="wdsInfotechLogo" />
            </NavLink>
          </div>
          <div className="col-lg-6 px-5 text-end">
            <small><i className="fa fa-envelope text-primary me-2" />info@wdsinfotech.com</small>
            <small className="ms-4"><i className="fa fa-phone-alt text-primary me-2" />Pune</small>
          </div>
        </div>
      </div>
      <div className="col-lg-6 px-4 text-start log-mob my-2">
        <NavLink to="/home" className="nav-logo">
          <img style={{ height: '45px', width: 'auto' }} src="/wdsInfotech.png" alt="wdsInfotechLogo" />
        </NavLink>
      </div>
      <div className={`container-fluid px-0 wow fadeIn ${isFixed ? 'fixed-top' : 'd-none'}`} data-wow-delay="0.1s">
        <Nav className="navbar navbar-expand-lg navbar-light py-lg-0 px-lg-1 wow fadeIn" data-wow-delay="0.1s">
          <NavLink to="/home" className="nav-logo">
            <img style={{ height: '45px', width: 'auto' }} src="/wdsInfotech.png" alt="wdsInfotechLogo" />
          </NavLink>
          <Button
            type="button"
            className="navbar-toggler me-3 my-2"
            data-bs-toggle="collapse"
            data-bs-target="#navbarCollapse"
          >
            <span className="navbar-toggler-icon" />
          </Button>
          <div className="collapse navbar-collapse" id="navbarCollapse">
            <div className="navbar-nav ms-auto p-4 p-lg-0">
              <a onClick={() => { handleClick('home'); }} href="/" className={isActive === 'home' ? 'nav-item nav-link active' : 'nav-item nav-link'}>Home</a>
              <a onClick={() => { handleClick('product'); }} href="/products" className={isActive === 'product' ? 'nav-item nav-link active' : 'nav-item nav-link'}>Our Products</a>
              <a href="#about" onClick={() => { handleClick('about'); }} className={isActive === 'about' ? 'nav-link nav-item active' : 'nav-link nav-item'}>About</a>
              {/* <div className="nav-item dropdown">
                <a onClick={() => { handleClick('product'); }} href="#product" className={isActive === 'product' ? 'nav-link dropdown-toggle active' : 'nav-link dropdown-toggle'} data-bs-toggle="dropdown">Products</a>
                <div className="dropdown-menu border-light m-0">
                  <a href="project.html" className="dropdown-item">CardWave</a>
                  <a href="team.html" className="dropdown-item">CampusXcel</a>
                  <a href="testimonial.html" className="dropdown-item">CryoCanva</a>
                </div>
              </div> */}
              <a href="#team" onClick={() => { handleClick('team'); }} className={isActive === 'team' ? 'nav-link nav-item active' : 'nav-link nav-item'}>Our Team</a>
              {/* <a href="contact.html" className="nav-item nav-link">Contact</a> */}
            </div>
            <div className="d-none d-sm-flex ms-4">
              <a className="btn padding-zero mx-1" href="https://www.linkedin.com/company/wdsinfotech" target="_blank" rel="noreferrer">
                <img style={{ height: '18px', width: 'auto' }} src="/images/public/linkedin-logo.png" alt="linkdein" />
              </a>
              <a className="btn padding-zero mx-1" href="/">
                <img style={{ height: '20px', width: 'auto' }} src="/images/public/insta-logo.png" alt="insta" />
              </a>
            </div>
          </div>
        </Nav>
      </div>
      {/* Initial fixed-top section */}
      {/* {!isFixed && (
        <div className="container-fluid fixed-top px-0 wow fadeIn" data-wow-delay="0.1s">
          <div className="top-bar row gx-0 align-items-center d-none d-lg-flex">
            <div className="col-lg-6 px-5 text-start">
              <a href="index.html" className="navbar-brand ms-4 ms-lg-0">
                <h1 className="display-5 text-primary m-0">Finanza</h1>
              </a>
              <small className="ms-4"><i className="fa fa-clock text-primary me-2" />9.00 am - 9.00 pm</small>
            </div>
            <div className="col-lg-6 px-5 text-end">
              <small><i className="fa fa-envelope text-primary me-2" />info@example.com</small>
              <small className="ms-4"><i className="fa fa-phone-alt text-primary me-2" />+012 345 6789</small>
            </div>
          </div>
        </div>
      )}

      {/* Navbar that becomes fixed on scroll
      <Row className={`navbar navbar-expand-lg navbar-light py-lg-0 px-lg-5 wow fadeIn fixed-top ${isFixed ? 'navbar-margin' : 'd-none'}`}>
        <Col xs="7">
          <div className="align-start">
            <a href="index.html" className="navbar-brand">
              <h1 className="display-5 text-primary m-0">Finanza</h1>
            </a>
          </div>
        </Col>
        <Col xs="5">
          <div className="align-end">
            <Button
              type="button"
              className="navbar-toggler me-2"
              data-bs-toggle="collapse"
              data-bs-target="#navbarCollapse"
            >
              <span className="navbar-toggler-icon" />
            </Button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
              <div className="navbar-nav ms-auto p-1 p-lg-0">
                <a href="index.html" className="nav-item nav-link active">Home</a>
                <a href="about.html" className="nav-item nav-link">About</a>
                <a href="service.html" className="nav-item nav-link">Services</a>
                <div className="nav-item dropdown">
                  <a href="/" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Pages</a>
                  <div className="dropdown-menu border-light m-0">
                    <a href="project.html" className="dropdown-item" onClick={handleClick}>Projects</a>
                    <a href="feature.html" className="dropdown-item">Features</a>
                    <a href="team.html" className="dropdown-item">Team Member</a>
                    <a href="testimonial.html" className="dropdown-item">Testimonial</a>
                    <a href="404.html" className="dropdown-item">404 Page</a>
                  </div>
                </div>
                <a href="contact.html" className="nav-item nav-link">Contact</a>
              </div>
              <div className="d-none d-lg-flex ms-2">
                <a className="btn btn-light btn-sm-square rounded-circle ms-3" href="/">
                  <small className="fab fa-facebook-f text-primary" />
                </a>
                <a className="btn btn-light btn-sm-square rounded-circle ms-3" href="/">
                  <small className="fab fa-twitter text-primary" />
                </a>
                <a className="btn btn-light btn-sm-square rounded-circle ms-3" href="/">
                  <small className="fab fa-linkedin-in text-primary" />
                </a>
              </div>
            </div>
          </div>
        </Col>
      </Row> Content of the page goes here */}
    </div>
  );
};

export default Navbar;
