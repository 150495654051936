import React from 'react';
import { Button } from 'reactstrap';

export const AcademicInternship: React.FC = () => (
  <div className="container-xxl service py-1">
    <div className="container">
      <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s">
        <p className="d-inline-block border rounded font-primary fw-semi-bold py-1 px-3">Digital Transformation</p>
        <h1 className="display-5 mb-5">Empowering businesses to thrive in the digital age through strategic modernization and innovation</h1>
      </div>
      <div className="row g-4 wow fadeInUp" data-wow-delay="0.3s">
        <div className="col-lg-4">
          <div className="nav nav-pills d-flex justify-content-between w-100 h-100 me-4">
            <Button
              className="nav-link w-100 d-flex align-items-center text-start border p-4 mb-4 active"
              data-bs-toggle="pill"
              data-bs-target="#tab-pane-1"
            >
              <h5 className="m-0"><i className="fa fa-bars text-primary me-3" />Strategic Roadmapping</h5>
            </Button>
            <Button
              className="nav-link w-100 d-flex align-items-center text-start border p-4 mb-4"
              data-bs-toggle="pill"
              data-bs-target="#tab-pane-2"
            >
              <h5 className="m-0"><i className="fa fa-bars text-primary me-3" />Technology Integration</h5>
            </Button>
            <Button
              className="nav-link w-100 d-flex align-items-center text-start border p-4 mb-4"
              data-bs-toggle="pill"
              data-bs-target="#tab-pane-3"
            >
              <h5 className="m-0"><i className="fa fa-bars text-primary me-3" />Process Optimization</h5>
            </Button>
            {/* <Button
              className="nav-link w-100 d-flex align-items-center text-start border p-4 mb-0"
              data-bs-toggle="pill"
              data-bs-target="#tab-pane-4"
            >
              <h5 className="m-0"><i className="fa fa-bars text-primary me-3" />Certificates</h5>
            </Button> */}
          </div>
        </div>
        <div className="col-lg-8">
          <div className="tab-content w-100">
            <div className="tab-pane fade show active" id="tab-pane-1">
              <div className="row g-4">
                <div className="col-md-6">
                  <div className="position-relative h-100">
                    <img
                      className="position-absolute rounded w-100 h-100"
                      src="/images/public/proj-gen.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-md-5">
                  <h3 className="mb-4">Strategic Roadmapping</h3>
                  <p className="mb-4">Crafting tailored digital transformation strategies by conducting in-depth assessments, setting clear objectives, and developing comprehensive roadmaps to guide organizations towards their desired digital future.</p>
                  {/* <p><i className="fa fa-check text-primary me-3" />Secured Loans</p>
                  <p><i className="fa fa-check text-primary me-3" />Credit Facilities</p>
                  <p><i className="fa fa-check text-primary me-3" />Cash Advanced</p> */}
                  <a href="#/" className="btn btn-primary py-3 px-5 mt-3">Read More</a>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="tab-pane-2">
              <div className="row g-4">
                <div className="col-md-6">
                  <div className="position-relative h-100">
                    <img
                      className="position-absolute rounded w-100 h-100"
                      src="/images/public/bootcamp.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-md-5">
                  <h3 className="mb-4">Technology Integration</h3>
                  <p className="mb-4">Leveraging cutting-edge technologies such as artificial intelligence, Internet of Things, and cloud computing to seamlessly integrate digital solutions into existing infrastructure, driving efficiency, scalability, and competitive advantage.</p>
                  <a href="#/" className="btn btn-primary py-3 px-5 mt-3">Read More</a>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="tab-pane-3">
              <div className="row g-4">
                <div className="col-md-6">
                  <div className="position-relative h-100">
                    <img
                      className="position-absolute rounded w-100 h-100"
                      src="/images/public/ws-ide.png"
                      alt=""
                    />
                  </div>
                </div>
                <div className="col-md-5">
                  <h3 className="mb-4">Process Optimization</h3>
                  <p className="mb-4">Optimizing organizational workflows and business processes through the identification and elimination of inefficiencies, automation of repetitive tasks, and implementation of best practices to enhance operational agility and responsiveness.
                  </p>
                  {/* <p><i className="fa fa-check text-primary me-3" />Full Stack Development</p>
                  <p><i className="fa fa-check text-primary me-3" />Diffrent Frameworks</p>
                  <p><i className="fa fa-check text-primary me-3" />Core Knowledge on languages</p> */}
                  <a href="#/" className="btn btn-primary py-3 px-5 mt-3">Read More</a>
                </div>
              </div>
            </div>
            <div className="tab-pane fade" id="tab-pane-4">
              <div className="row g-4">
                <div className="col-md-6">
                  <div className="position-relative h-100">
                    <img
                      className="position-absolute rounded w-100 h-100"
                      src="img/service-4.jpg"
                      alt=""
                    />
                  </div>
                </div>
                {/* <div className="col-md-6">
                  <h3 className="mb-4">Certificate of Practical Mastery</h3>
                  <p className="mb-4">Awarding Real Practical Knowledge: Our approach values genuine practical knowledge, empowering you with skills that extend beyond theoretical boundaries.
                  </p>
                  <a href="#/" className="btn btn-primary py-3 px-5 mt-3">Read More</a>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default AcademicInternship;
