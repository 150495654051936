import React from 'react';

export const ACPS: React.FC = () => (
  <div className="container-xxl feature py-1">
    <div className="container py-1">
      <div className="row g-5 align-items-center py-1">
        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
          <p className="d-inline-block border rounded font-primary fw-semi-bold py-1 px-3">
            <img
              alt="..."
              height="45px"
              src="/images/products/acps.png"
            />
          </p>
          <div className="align-start mb-3">
            <a href="https://acps.in/" target="_blank" className="alert-error" rel="noreferrer">www.acps.in</a>
          </div>
          <h1 className="display-5 mb-4">Effortlessly prepare and print bulk files in three simple steps: Upload, Generate, Print</h1>
          <p className="mb-4 text-common">Effortlessly convert extensive CSV data into printable layouts. Streamline the process without concerns about printing, stacking, or arranging cards in the desired format.
          </p>
        </div>
        <div className="col-lg-6">
          <div className="row g-4 align-items-center">
            <div className="col-md-6">
              <div className="row g-4">
                <div className="col-12 wow fadeIn" data-wow-delay="0.3s">
                  <div className="feature-box border rounded p-4">
                    <i className="fa fa-check fa-3x text-primary mb-3" />
                    <h4 className="mb-3">Streamlined Processing for Print-Ready Files</h4>
                    <p className="mb-3">Effortlessly manage large textual files as ACPS efficiently processes card data, converting it into a print-ready format.
                    </p>
                  </div>
                </div>
                <div className="col-12 wow fadeIn" data-wow-delay="0.5s">
                  <div className="feature-box border rounded p-4">
                    <i className="fa fa-check fa-3x text-primary mb-3" />
                    <h4 className="mb-3">Intuitive Dashboard</h4>
                    <p className="mb-3">Access a user-friendly dashboard for comprehensive activity monitoring, seamlessly overseeing billing cycles, and providing an end-to-end solution. Simplify the intricate task of monitoring each aspect with ease, ensuring a straightforward and efficient experience for users
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 wow fadeIn" data-wow-delay="0.7s">
              <div className="feature-box border rounded p-4">
                <i className="fa fa-check fa-3x text-primary mb-3" />
                <h4 className="mb-3">Effortless Card Sequencing for Efficient Mass Printing</h4>
                <p className="mb-3">Effortlessly process large and heavy files without any hassle, regardless of the volume you need to handle in a single day. Our streamlined solution ensures efficient processing, enabling you to tackle any file count effortlessly. Experience seamless and stress-free file management, making it easy to handle bulk data with ease and speed.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default ACPS;
