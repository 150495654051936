import React from 'react';
import ReactPlayer from 'react-player';
import './home.scss';
import { Row, Col, CardImg } from 'reactstrap';

export const MyPrint: React.FC = () => (
  <div className="container-xxl1">
    <div className="container mobile-padding-web">
      <div className="row g-4 align-items-end mb-4">
        <div className="col-lg-12 wow fadeInUp" data-wow-delay="0.3s">
          <p className="d-inline-block border rounded font-primary fw-semi-bold py-1 px-3">Printing solutions made easier</p>
          <Row className="align-center">
            <Col lg="11">
              <div className="text-center-aligned">
                <h1 className="display-5"><span className="ai-text">AI-Powered</span> Precision: Redefining Card Printing with Our Zero-Error Processing System</h1>
              </div>
            </Col>
            <Col lg="5">
              <div className="align-center wow fadeInUp pt-4" data-wow-delay="0.1s">
                <ReactPlayer
                  url="https://media-campusxcel.s3.ap-southeast-1.amazonaws.com/wdsinfotech/intro-yocard.mp4"
                  controls
                  width="100%"
                  height="auto"
                />
                {/* <CardImg alt="" src="/images/products/prod-wds-aayushman.png" /> */}
              </div>
            </Col>
            <Col lg="7">
              <div className="pt-4 ml-1 align-text-justify" data-wow-delay="0.1s">
                <p className="mb-4">Step into a new era of card printing precision with our state-of-the-art processing system, driven by Artificial Intelligence. Our AI-powered technology ensures an impeccable zero-error processing experience, revolutionizing the conversion of massive data loads into printable cards. Whether handling PDF or CSV files, the synergy of human expertise and AI intelligence guarantees swift and flawless transformations, making the printing process remarkably easy and efficient. Specializing in Ayushman Cards across various formats, our AI seamlessly eliminates errors, delivering print-ready results with unprecedented accuracy. Experience the future of printing, where AI not only enhances efficiency but ensures a flawless journey from data input to the final card in hand.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </div>
      <div className="border rounded p-4 wow fadeInUp" data-wow-delay="0.1s">
        <div className="row g-4">
          <div className="col-lg-6 wow fadeIn" data-wow-delay="0.1s">
            <div className="h-100">
              <div className="d-flex">
                <div className="flex-shrink-0">
                  <img style={{ height: '30px', width: 'auto' }} src="/images/products/csv-file.png" alt="csv" />
                </div>
                <div className="ps-3">
                  <h4>Bulk CSV Processing</h4>
                  <span>Effortless Printing Excellence: Unleashing the Power of Error-Free Data Conversion</span>
                </div>
                <div className="border-end d-none" />
              </div>
              <div className="align-center pt-4">
                <img alt="" className="img-fluid printer-img" src="/images/clients/canon-1k.jpeg" />
              </div>
              <div className="border-bottom mt-4 d-block d-lg-none" />
            </div>
          </div>
          <div className="col-lg-6 wow fadeIn" data-wow-delay="0.5s">
            <div className="h-100">
              <div className="d-flex">
                <div className="flex-shrink-0">
                  <img style={{ height: '30px', width: 'auto' }} src="/images/products/pdf.png" alt="pdf" />
                </div>
                <div className="ps-3 mr-2">
                  <h4>EKyc Pdf Card Printing</h4>
                  <span>Effortlessly prepare and print bulk files in three simple steps: Upload, Generate, Print</span>
                </div>
                <div className="border-end d-none" />
              </div>
              <div className="align-center pt-4">
                <img alt="" className="img-fluid printer-img" src="/images/clients/komori-machine.jpeg" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="pt-5">
          <h4>How this works</h4>
        </div>
        <Row className="pt-5">
          <Col lg={{ size: 7, order: 2 }} xs={{ order: 1 }}>
            <div className="pt-2 align-center">
              <h5 className="ai-text">AI-Powered Processing</h5>
            </div>
            <div className="pt-4 align-center">
              <span>Immerse yourself in the cutting-edge world of card processing with our revolutionary AI technology. Our system leverages Artificial Intelligence to meticulously analyze and convert massive data loads into printable cards, ensuring a zero-error processing experience. Through the seamless integration of AI, we guarantee precision and accuracy at every stage, setting new standards in the industry.</span>
            </div>
          </Col>
          <Col lg={{ size: 5, order: 1 }} xs={{ order: 2 }}>
            <div className="pt-2 m-2">
              <CardImg className="print-gif" alt="" src="/videos/ai-card-process.gif" />
            </div>
          </Col>
        </Row>
        <Row className="pt-5 my-5">
          <Col lg={{ size: 7, order: 1 }} xs={{ order: 1 }}>
            <div className="pt-2 align-center">
              <h5 className="ai-text">User-Friendly Monitoring Dashboard</h5>
            </div>
            <div className="pt-4 align-center">
              <span>Navigate the entire card production journey effortlessly with our user-friendly dashboard. This powerful feature allows administrators to monitor the processing flow, from data input to printing, and finally, the distribution of cards to end-users. Stay in control with real-time updates, ensuring a transparent and streamlined process. Our intuitive dashboard puts you at the helm, making oversight and management an intuitive and stress-free experience.</span>
            </div>
          </Col>
          <Col lg={{ size: 5, order: 2 }} xs={{ order: 2 }}>
            <div className="pt-4">
              <ReactPlayer
                url="/videos/yo-dashboard.mp4"
                controls
                width="100%"
                height="auto"
              />
            </div>
          </Col>
        </Row>
        <Row className="pt-5 my-5">
          <Col lg={{ size: 7, order: 2 }} xs={{ order: 1 }}>
            <div className="pt-2 align-center">
              <h5 className="ai-text">Comprehensive Card Sorting Package</h5>
            </div>
            <div className="pt-4 align-center">
              <span>Experience the convenience of a comprehensive solution in a single product. Our offering goes beyond processing and printing – it provides an integrated package for sorting cards village/town, district, and state-wise. This unique feature simplifies the organization and distribution of cards, ensuring they reach the right recipients with maximum efficiency. Elevate your card production process with a solution designed to meet the specific needs of communities, making the entire experience efficient and tailored to your requirements.</span>
            </div>
          </Col>
          <Col lg={{ size: 5, order: 1 }} xs={{ order: 2 }}>
            <div className="pt-2 m-2">
              <CardImg className="print-gif" alt="" src="/videos/happy-users.gif" />
            </div>
          </Col>
        </Row>
      </div>
    </div>
    <div className="pt-5" />
  </div>
);

export default MyPrint;
