import React from 'react';

export const YoCard: React.FC = () => (
  <div className="container-xxl feature py-1">
    <div className="container py-1">
      <div className="row g-5 align-items-center py-1">
        <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
          <p className="d-inline-block border rounded font-primary fw-semi-bold py-1 px-3">
            <img
              alt="..."
              height="45px"
              src="/images/products/yoCard.png"
            />
          </p>
          <div className="align-start mb-3">
            <a href="https://yocard.in/" target="_blank" className="alert-error" rel="noreferrer">www.yocard.in</a>
          </div>
          <h1 className="display-5 mb-4">Revolutionary Approach to Transforming Complex PDFs into Print-Ready Formats</h1>
          <h6 className="mb-4 text-common">Obtain print-ready PDF cards for streamlined conversion of large-scale PDF data into printable layouts. Enhance supervision of employee assignments across various domains, promoting effective task administration and improved oversight of team activities.
          </h6>
        </div>
        <div className="col-lg-6">
          <div className="row g-4 align-items-center">
            <div className="col-md-6">
              <div className="row g-4">
                <div className="col-12 wow fadeIn" data-wow-delay="0.3s">
                  <div className="feature-box border rounded p-4">
                    <i className="fa fa-check fa-3x text-primary mb-3" />
                    <h4 className="mb-3">Seamless Data Transformation</h4>
                    <p className="mb-3">Yo-card effortlessly transforms your data into a print-ready format, overcoming any constraints in the printing process. Regardless of your data&apos;s original format, Yo-card ensures seamless conversion, eliminating hurdles and facilitating a smooth printing experience.
                    </p>
                  </div>
                </div>
                <div className="col-12 wow fadeIn" data-wow-delay="0.5s">
                  <div className="feature-box border rounded p-4">
                    <i className="fa fa-check fa-3x text-primary mb-3" />
                    <h4 className="mb-3">Intuitive Dashboard</h4>
                    <p className="mb-3">Access a user-friendly dashboard for comprehensive activity monitoring, seamlessly overseeing billing cycles, and providing an end-to-end solution. Simplify the intricate task of monitoring each aspect with ease, ensuring a straightforward and efficient experience for users
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 wow fadeIn" data-wow-delay="0.7s">
              <div className="feature-box border rounded p-4">
                <i className="fa fa-check fa-3x text-primary mb-3" />
                <h4 className="mb-3">Effortless Card Sequencing for Efficient Mass Printing</h4>
                <p className="mb-3">Simplify the mass printing process with Yo-card, seamlessly organizing multi-card layouts into print-ready formats. Sorting based on multiple locations streamlines the stacking process, enabling swift preparation for delivery. Experience the ease of preparing and sending cards to various destinations in no time, optimizing your printing workflow with precision and efficiency.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default YoCard;
