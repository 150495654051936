import React, { MutableRefObject, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import './public.scss';
import NavbarData from '../../components/public/home/navbar';
import { Intro } from '../../components/public/home/intro';
import AboutUs from '../../components/public/home/about-us';
import { Products } from '../../components/public/home/products';
import { AcademicInternship } from '../../components/public/home/academic-internship';
import { TechSoftSkill } from '../../components/public/home/tech-softskill';
import WDSTeam from '../../components/public/home/wds-team';
import WDSFooter from '../../components/public/home/footer';
import { Clients } from '../../components/public/home/clients';

export const PublicWDSInfotechComponent: React.FC = () => {
  const location = useLocation();
  const aboutDiv = useRef() as MutableRefObject<HTMLDivElement>;
  const productDiv = useRef() as MutableRefObject<HTMLDivElement>;
  const teamDiv = useRef() as MutableRefObject<HTMLDivElement>;
  React.useLayoutEffect(() => {
    if (location.hash === '#about') {
      aboutDiv.current.scrollIntoView({ behavior: 'smooth' });
    } else if (location.hash === '#product') {
      productDiv.current.scrollIntoView({ behavior: 'smooth' });
    } else if (location.hash === '#team') {
      teamDiv.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  return (
    <div>
      <NavbarData />
      <Intro />
      <div ref={aboutDiv} className="pt-5">
        <AboutUs />
      </div>
      <div ref={productDiv}>
        <Products />
      </div>
      <div>
        <AcademicInternship />
      </div>
      <div>
        <TechSoftSkill />
      </div>
      <div ref={teamDiv}>
        <WDSTeam />
      </div>
      <div>
        <Clients />
      </div>
      <div>
        <WDSFooter />
      </div>
    </div>
  );
};

export default PublicWDSInfotechComponent;
