import React from 'react';
import './home.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { Link } from 'react-router-dom';

export const WDSTeam: React.FC = () => (
  <div className="container-xxl py-5">
    <div className="container">
      <div className="text-center mx-auto wow fadeInUp" data-wow-delay="0.1s">
        <p className="d-inline-block border rounded font-primary fw-semi-bold py-1 px-3">Our Team</p>
        <h1 className="display-5 mb-5">Lead Team</h1>
      </div>
      <div className="row g-4 align-center">
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.1s">
          <div className="hex">
            <img className="img-team" src="/images/clients/shaz.jpg" alt="t3" />
          </div>
          <div className="team-text align-center pt-3">
            <h4 className="mb-0">Shahzia Zameer</h4>
            <div className="team-social d-flex">
              <Link to="https://www.linkedin.com/in/shazia-zameer-4a759915a/" target="_blank">
                <FontAwesomeIcon icon={faLinkedin} className="icon-linkdein mx-2 pt-1" />
              </Link>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.3s">
          <div className="hex">
            <img className="img-fluid rounded" src="/images/clients/nim.jpg" alt="t2" />
          </div>
          <div className="team-text align-center pt-3">
            <h4 className="mb-0">Nimisha Singh</h4>
            <div className="team-social d-flex">
              <Link to="https://www.linkedin.com/in/nimisha-singh-10508b7a/" target="_blank">
                <FontAwesomeIcon icon={faLinkedin} className="icon-linkdein mx-2 pt-1" />
              </Link>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 wow fadeInUp" data-wow-delay="0.5s">
          <div className="hex align-center">
            <img className="min-image-profile img-fluid rounded" src="/images/clients/jeet.jpg" alt="t1" />
          </div>
          <div className="team-text align-center pt-3">
            <h4 className="mb-0">Jeet Jha</h4>
            <div className="team-social d-flex">
              <Link to="https://www.linkedin.com/in/jeetendra-jha-66578a33/" target="_blank">
                <FontAwesomeIcon icon={faLinkedin} className="icon-linkdein mx-2 pt-1" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default WDSTeam;
